import React, { useEffect } from 'react'
import { UserContext } from './userContext'
import { useState } from "react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function UserProvider(props) {
  //  const baseURL='http://127.0.0.1:8000/api';
   const baseURL = 'https://shortlisteddashboard.msol.dev/api';
    // const baseURL = 'https://admin.shopshortlisted.com/api';


  const defaultImage = `${process.env.PUBLIC_URL}/assests/default_img.svg`;
  const [token, setToken] = useState(localStorage.getItem("token") || "");
  const [user, setUser] = useState(localStorage.getItem("user") || "");
  const [brands, setBrands] = useState([])
  const [currentBrand, setCurrentBrand] = useState('')
  const [isfromBrandHeader, setIsfromBrandHeader] = useState(false)
  const [shopSetting, setShopSetting] = useState({})
  const [searchInput, setSearchInput] = useState('');
  const [shopTitle, setShopTitle] = useState('');
  const [headerDate, setHeaderDate] = useState({});
  const [favLength, setFavLength] = useState(null);
  const [pag, setPag] = useState({first: 0,from: 0,to: 0,totalRecords:0,rows:12});

  const login = (data) => {
    localStorage.setItem('token', data.access_token);
    setToken(data.access_token);
    setUser(data.user);
  };

  const logout = () => {
    localStorage.removeItem('token');
    setToken('');
    setUser('');
  };

  //-----------------------------  clear all pagination ------------------------------
  const page_one={first: 0,from: 0,to: 0,totalRecords:0,rows:12}
  const clearPagination=()=>{
    setPag({...page_one});
  }
  //-----------------------------  get brands ------------------------------
  const navigate = useNavigate();

  const check_token = () => {
    axios.post(`${baseURL}/check_token`,{},{ headers: { 'Authorization': `Bearer ${token}` } })
      .then((response) => console.log(response))
      .catch((error) =>{
        logout();
        navigate('/login')

      })
  }
  //-----------------------------  get brands ------------------------------
  const getBrands = () => {
    axios.get(`${baseURL}/brands`, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((response) => setBrands(response.data.data))
      .catch( )
  }

  //-----------------------------  toggle brands ------------------------------
  const updateIsFromBrandHeader = (flag, brand) => {
    setIsfromBrandHeader(flag);
    setCurrentBrand(brand)

  };

  //-----------------------------  toggle shop Title ------------------------------
  const updateShopTitle = (title,) => {
    setShopTitle(title);
  };

  //-----------------------------  get shop Settings ------------------------------
  const GetShopSettings = () => {
    axios.get(`${baseURL}/shop/images`)
      .then((response) => setShopSetting({ ...shopSetting, images: response.data.data.slider_images, footer: response.data.data.footer.url }))
      .catch( )
  }
  //----------------------------- handle search ------------------------------
  const handleSearhChange = (keyword) => {
    setSearchInput(keyword)
  }
  //-------------------------------------sesstion-id/////////////


  //----------------------------- update header data  ------------------------------
  const updateHeaderData = (key, value) => {
    if (key === 'clear') {
      setHeaderDate(null)
      // alert('1')
    } else {
      setHeaderDate({
        [key]: value
      })
    }
    if (key === 'brand') {
      axios.get(`${baseURL}/brands/show/${value.id}`, { headers: { 'Authorization': `Bearer ${token}` } })
        .then((response) => setShopSetting({ ...shopSetting, images: response.data.data.slider_images, footer: response.data.data.footer.url }))
        .catch( )
    } else if (key === 'exclusive') {
      axios.get(`${baseURL}/exclusive/images`)
        .then((response) => setShopSetting({ ...shopSetting, images: response.data.data.images, footer: response.data.data.footer.url }))
        .catch( )
    } else if (key === 'collection') {
      axios.get(`${baseURL}/collections/images`)
        .then((response) => setShopSetting({ ...shopSetting, images: response.data.data.images, footer: response.data.data.footer.url }))
        .catch( )
    }
    else {
      GetShopSettings();
    }
  }
  //----------------------------- clear header data  ------------------------------
  const clearHeaderData = () => {
    setHeaderDate(null);
  }

  //----------------------------- get favorites length  ------------------------------
  const getFavoritesLength = () => {
    if(token){
    axios.get(`${baseURL}/favorites/count`, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((response) => { setFavLength(response.data.count) })
      .catch( )
    }
  }
  //----------------------------- update favorites length  ------------------------------
  const updateFavLength = (isAdd) => {
    if (isAdd) {
      setFavLength(favLength + 1)
    } else {
      setFavLength(favLength - 1)
    }

  }


  useEffect(() => {
    if(token){
      check_token();
    }
    getBrands();
    getFavoritesLength();
  }, []);
  // -------------------------------------------------------------------------

  const [updatePage, setUpdatePage] = useState(false);


  return (
    <UserContext.Provider value={{ baseURL, token, login, logout, user, brands, currentBrand, isfromBrandHeader, shopSetting, handleSearhChange, updateIsFromBrandHeader, GetShopSettings, searchInput, updateShopTitle, shopTitle, headerDate, updateHeaderData, defaultImage, favLength, updateFavLength, clearHeaderData, updatePage, setUpdatePage,setPag,pag , clearPagination}}>{props.children}</UserContext.Provider>
  )
}
