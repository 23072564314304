import React, { useState, useContext, useEffect } from 'react'
import '../css/checkout.scss'
import '../css/responsive/checkout_res.scss'
import Accordion from 'react-bootstrap/Accordion';
import { Dropdown } from 'primereact/dropdown';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { UserContext } from '../context/userContext';
import axios from 'axios';
import { CartContext } from '../context/cartContext';

export default function Checkout() {
    const navigate = useNavigate();
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedStates, setSelectedStates] = useState(null);
    const [countries, setCountries] = useState(null);
    const [User, setUser] = useState(null);
    const [contactInfo, setContactInfo] = useState({
        email: '',
        first_name: '',
        last_name: '',
        company: '',
        address: '',
        phone: '',
        zip_code: '',
        discount: '',
        city: '',
        note: '',
        state_id:'',
        country_id:'',
    });
    const [states, setStates] = useState(null);
    const [defaultState, setDefaultState] = useState(null);
    let { baseURL, token, defaultImage } = useContext(UserContext);
    const { carts, setCarts, subTotal, handleIncrement, handleDecrement, deleteCartItem, handelUpdateShiping, getNewCart, cartLength, sesId, loadIcon, setLoadIcon, cartsNumber } = useContext(CartContext);
    const [isUS, setIsUS] = useState(true)
    const [hasInternational, setHasInternational] = useState(true)
    const [hiddenState, setHiddenState] = useState('');
    const [hiddenCountry, setHiddenCountry] = useState('');



    const chechHasInternational = () => {
        // console.log(carts);
    }
    // ----------------------------------------get countries ----------------------------------------------------------------------
    useEffect(() => {
        axios.get(baseURL + '/countries', { headers: { 'Authorization': `Bearer ${token}` } })
            .then(res => {
                setCountries(res.data.data.map(country => ({
                    name: country.name,
                    code: country.code,
                    id:country.id,
                    provinces: country.provinces
                })))
              
                setContactInfo({
                    country_id:res.data.data[0].id,
                    state_id:res.data.data[0].provinces[0].id
                });
                setSelectedCountry(res.data.country)
                const stateObject = JSON.parse(res.data.state);
                const selectedCountryData = res.data.data.find(country => country.name === res.data.country);
                if (selectedCountryData) {
                    setStates(selectedCountryData.provinces);
                   
                    if (token) {
                        setDefaultState(stateObject.name);
                    } else {
                        //  setSelectedStates(selectedCountryData.provinces[0].name);
                    }
                }

            })
            .catch(error => { })

        if (token) {
            axios.get(`${baseURL}/user/address`, { headers: { 'Authorization': `Bearer ${token}` } })
                .then((response) => {
                    if (response.data && response.data.data) {
                        const userData = response.data.data;
                        setContactInfo({
                            first_name: userData.user.first_name,
                            last_name: userData.user.last_name,
                            company: userData.address.company,
                            address: userData.address.address,
                            phone: userData.address.phone,
                            zip_code: userData.address.zipcode,
                            city: userData.address.city,
                            apartment: userData.address.apartment

                        });
                    } else {

                    }

                })
                .catch((error) => { })
        }

    }, [baseURL, token])


    
    useEffect(() => {
        if (defaultState)
            setSelectedStates(defaultState);
    }, [defaultState])




    const [contactInfoError, setContactInfoError] = useState('');
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setContactInfo(prevContactInfo => ({
            ...prevContactInfo,
            [name]: value
        }));
    };


    

    const Checkout = () => {
        const newErrors = {};
        let noError = true;



        // Iterate through each cart and check for errors
        carts.forEach(cart => {
            if (shiping[cart.brand.id] === undefined || shiping[cart.brand.id] === null) {
                newErrors[cart.brand.id] = 'Please select a shipping option.';
                noError = false;
            }
            cart.products.forEach(product => {
                if (product.out_of_stock === 1) {
                    newErrors[product.id] = 'This product is out of stock.';
                    noError = false;
                }
            });
        });

        // After checking all carts, set errors if any were found
        if (Object.keys(newErrors).length > 0) {
            setShipingError(newErrors);
            // console.log('error2',newErrors);
        }



        // Proceed only if no errors were found
        if (noError) {
            setLoadIcon(true);
            if (token) {
                axios.post(`${baseURL}/orders`, {
                    payment_method: 'visa',
                    country: selectedCountry,
                    state: selectedStates,
                    first_name: contactInfo.first_name,
                    last_name: contactInfo.last_name,
                    address: contactInfo.address,
                    city: contactInfo.city,
                    zipcode: contactInfo.zip_code,
                    discount: 0,
                    discount_type: 'fixed',
                    shipping: shiping,
                    // shipping_name:shippingName,
                    note: contactInfo.note,
                    // address2: contactInfo.address2,
                    apartment: contactInfo.apartment,
                    country_id:contactInfo.country_id,
                    state_id:contactInfo.state_id,

                }, { headers: { 'Authorization': `Bearer ${token}` } })
                    .then((response) => {
                        console.log(response);
                        window.location.href = response.data.url;
                        // setLoadIcon(false);
                        setLoadIcon(false);
                    })
                    .catch((error) => {
                        setLoadIcon(false);
                        if (error.response && error.response.data && error.response.data.message === "Validation errors") {
                            // If the error message indicates validation errors, update the contactInfoError
                            setContactInfoError(error.response.data.data);
                        } else {
                            setCarts(error.response.data.data)
                        }
                    });
            }
            else {
                axios.post(`${baseURL}/orders`, {
                    payment_method: 'visa',
                    country: selectedCountry,
                    state: selectedStates,
                    first_name: contactInfo.first_name,
                    last_name: contactInfo.last_name,
                    address: contactInfo.address,
                    city: contactInfo.city,
                    zipcode: contactInfo.zip_code,
                    discount: 0,
                    discount_type: 'fixed',
                    shipping: shiping,
                    session_id: sesId,
                    note: contactInfo.note,
                    // address2: contactInfo.address2,
                    apartment: contactInfo.apartment,
                    email: contactInfo.email,
                    country_id:contactInfo.country_id,
                    state_id:contactInfo.state_id,
                })
                    .then((response) => {

                        window.location.href = response.data.url;
                        // setLoadIcon(false);
                    })
                    .catch((error) => {
                        setLoadIcon(false);
                        if (error.response && error.response.data && error.response.data.message === "Validation errors") {
                            // If the error message indicates validation errors, update the contactInfoError
                            setContactInfoError(error.response.data.data);
                        } else {
                            
                            setCarts(error.response.data.data)
                            
                        }
                    });
            }
        }
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();
    }

    let current_country = '';
    const handleCountryChange = (e) => {
        setHiddenCountry(e.value.name);
       
        setSelectedCountry(e.value.name);
        current_country = e.value.code
        const currentCountry = countries.find(country => country.name === e.value.name);
        if (currentCountry) {
            // console.log(currentCountry);
            setContactInfo({
                country_id:currentCountry.id,
                state_id: currentCountry.provinces && currentCountry.provinces.length > 0 ? currentCountry.provinces[0].id : undefined

            });
            setStates(currentCountry.provinces.map(state => ({
                name: state.name,
                code: state.code,
                id:state.id,
            })));
            let current_state = '';
            if (currentCountry.provinces.length > 0) {
                setSelectedStates(currentCountry.provinces[0].name);
                current_state = currentCountry.provinces[0].code
            }
            setShiping(prevShiping => {
                const updatedShiping = {};
                carts.forEach(cart => {
                    updatedShiping[cart.brand.id] = null; // Reset to null to uncheck radios
                });
                
                return updatedShiping;
            });
            setTotalShipping(0);
            setTimeout(() => {
                getNewCart(current_country, current_state);
                handleNewState(current_country);
                chechHasInternational();
            }, 0);
        }
        // alert(e.value.code)

    };

    const handleAutoFillChange = (event) => {
        console.log(states,'statesssss');
        const selectedState = states.find(state => state.name === event.target.value || state.code === event.target.value);
        if (selectedState) {
            setSelectedStates(selectedState.name);
            setHiddenState(selectedState.name);
            
        } else {
            // setSelectedStates(event.target.value);
            // setHiddenState(event.target.value);

        }

    };
    const handleAutoFillChangeCountry = (event) => {
        // alert(event.target.value);
        // console.log(event.target.value);
        // console.log(event.target);
        setShiping(prevShiping => {
            const updatedShiping = {};
            carts.forEach(cart => {
                updatedShiping[cart.brand.id] = null; // Reset to null to uncheck radios
            });
            
            return updatedShiping;
        });
        const selectedCountry = countries.find(country => country.name === event.target.value || country.code === event.target.value);
        if (selectedCountry) {
            // alert('changecountry');
            handleNewState(selectedCountry.name);
            setSelectedCountry(selectedCountry.name);
            setHiddenCountry(selectedCountry.name);
            
            setStates(selectedCountry.provinces.map(state => ({
                name: state.name,
                code: state.code,
                id:state.id,
            })));
        } else {
            // setSelectedCountry(event.target.value);
            // setHiddenCountry(event.target.value);

        }

    };



    const handleStateChange = (e) => {
        setSelectedStates(e.value.name);
        setHiddenState(e.value.name)
        getNewCart(current_country, e.value.code)
       
        setContactInfo({
           
            state_id: e.value.id && e.value.id > 0 ? e.value.id : undefined

        });
        //  alert(e.value.name)
    };
    const handleNewState = (selectedCountry) => {
        // alert(selectedCountry);
        
        if (selectedCountry !== 'US'&& selectedCountry !== 'United States') {
            setIsUS(false)
            setHiddenState('');
        } else {
            setIsUS(true)
        }
    }
    // -----------------------------------------------------------------------------------------------
    const [shiping, setShiping] = useState({})
    const [shipingError, setShipingError] = useState({})
    const [totalShipping, setTotalShipping] = useState(0)

    const [shippingName, setShipingName]=useState({});
    // useEffect(() => {
    //     setShipingName(shiping);
    //   }, [shiping]);


    const updateShiping = (brand_id, new_shiping,shippingType) => {
        setShipingName(prevState => ({
            ...prevState, 
            [brand_id]: shippingType 
        }));
        let data = {
            brand_id,
            shipping: new_shiping
        }
        if (!token) {
            data = {
                ...data,
                session_id: sesId
            }
        }
        let old_shiping = shiping[brand_id] ?? 0;
        axios.post(`${baseURL}/orders/get-total`, data, { headers: { 'Authorization': `Bearer ${token}` } })
            .then((response) => {
                let total = parseFloat(response.data.total).toFixed(2)

                setShiping({
                    ...shiping,
                    [brand_id]: new_shiping
                })
                setShipingError(prevState => ({
                    ...prevState,
                    [brand_id]: null,
                }));
                setTotalShipping(totalShipping + new_shiping - old_shiping);
                handelUpdateShiping(brand_id, response.data.total, 0)


            })
            .catch((error) => {

            });
        // let old_shiping = shiping[brand_id] ?? 0;
        // setShiping({
        //     ...shiping,
        //     [brand_id]: new_shiping
        // })
        // setShipingError(prevState => ({
        //     ...prevState,
        //     [brand_id]: null,
        // }));
        // setTotalShipping(totalShipping + new_shiping - old_shiping);
        // handelUpdateShiping(brand_id, new_shiping, old_shiping)
    }

    // -----------------------------------------------------------------------------------------------
    const DeleteItem = (id, total, islast, brand_id) => {
        if (islast) {
            let old_shiping = shiping[brand_id] || 0;  // Safeguard against undefined values

            // Check if the shipping cost is non-zero
            if (old_shiping !== 0) {
                let updatedShiping = { ...shiping };
                delete updatedShiping[brand_id];

                // Ensure totalShipping is a valid number before subtracting
                const newTotalShipping = totalShipping - old_shiping;
                setTotalShipping(isNaN(newTotalShipping) ? 0 : newTotalShipping);
                setShiping(updatedShiping);
            }
        }
        deleteCartItem(id, total);
    };



    // -----------------------------------------------------------------------------------------------
    const handleImageError = (e) => {
        e.target.src = defaultImage;
    };
    const getBrandName = (name) => {
        if (selectedCountry === 'United States') {

            let ss = name.replace('International', '');
            //    

            return ss;
        }
        else {
            let ss = name.replace('Standard', '');
            // ss = name.replace('Express', '');
            return ss;
        }

    };
    return (
        <>
            {loadIcon && (
                <div className="loading-container">
                    <img src="./assests/loading-icon.gif" alt="Loading..." className="checkout-video" />
                </div>
            )}
            <div className='checkout'>

                {!loadIcon && (
                    <div className="checkout-wrapper-1">

                        <form onSubmit={handleFormSubmit} className='checkout-form'>
                            <div className="checkout-left-right">
                                {/* --------------------------------- left section---------------------------------------------------- */}
                                <div className="checkout-left">

                                    <div className="checkout-express">Express checkout</div>
                                    {/* 
                            <div className="checkout-payments">
                                <div className="checkout-pay-img">
                                    <img src="./assests/checkout-3.png" alt="" />
                                </div>
                                <div className="checkout-pay-img">
                                    <img src="./assests/checkout-2.png" alt="" />
                                </div>
                                <div className="checkout-pay-img">
                                    <img src="./assests/checkout-1.png" alt="" />
                                </div>
                            </div>
                            <div className="checkou-or">OR</div> */}

                                    {!token && (
                                        <>
                                            <div className="checkout-contact-login">
                                                <div className="checkout-contact"> <img src="./assests/checkout-pencil.png" alt="" className='checkout-pencil' /> Contact</div>
                                                {/* <Link to='/login' className="checkout-login">Login</Link> */}
                                            </div>
                                            <input type="text" name="email" id='email' className='checkout-input2' placeholder='Email or mobile phone number' value={contactInfo.email} onChange={handleInputChange} required />
                                            {contactInfoError.email && <div className="error-msg2"> {contactInfoError.email}</div>}

                                            {/* <div className="checkout-email checkout-email-mob ">
                                        <input type="checkbox" name="" id="" />
                                        <div className="">Email me with news and offers</div>
                                    </div> */}
                                        </>
                                    )}

                                    <div className="checkout-contact2">
                                        <img src="./assests/checkout-delivery.png" alt="" className='checkout-pencil' /> Delivery</div>
                                    {/* <div className="w-100 flex justify-content-center">
                                <Dropdown
                                    value={cities.find(city => city.name === selectedCity)}
                                    onChange={handleCityChange}
                                    options={cities}
                                    optionLabel="name"
                                    placeholder="Select a Country"
                                    className="w-full md:w-14rem w-100"
                                    checkmark={true}
                                    highlightOnSelect={false}
                                />

                            </div> */}
                                    {/* {contactInfoError.country && <div className="error-msg2"> {contactInfoError.country}</div>} */}

                                    <div className="checkout-email">
                                        <input type="text" name="first_name" id="first_name" placeholder='First name' className='checkout-input2' value={contactInfo.first_name} onChange={handleInputChange} required />
                                        <input type="text" name="last_name" placeholder='Last name' className='checkout-input2' value={contactInfo.last_name} onChange={handleInputChange} required />
                                    </div>
                                    <div className="checkout-email" style={{ marginTop: '0' }}>
                                        {contactInfoError.first_name && <div className="error-msg2"> {contactInfoError.first_name}</div>}
                                        {contactInfoError.last_name && <div className="error-msg2"> {contactInfoError.last_name}</div>}

                                    </div>
                                    <input type="text" name="company" id="" className='checkout-input2' placeholder='Company (optional)' value={contactInfo.company} onChange={handleInputChange} />
                                    {contactInfoError.company && <div className="error-msg2"> {contactInfoError.company}</div>}


                                    <input type="text" name="address" className='checkout-input2' placeholder='Address' value={contactInfo.address} onChange={handleInputChange} required />
                                    {contactInfoError.address && <div className="error-msg2"> {contactInfoError.address}</div>}

                                    <input type="text" placeholder='Apartment, suite, etc. (optional)' className='checkout-input2' value={contactInfo.apartment} name="apartment" onChange={handleInputChange} />

                                    {/* <input type="text" name="address2" className='checkout-input2' placeholder='Second address (optional) ' onChange={handleInputChange}  value={contactInfo.address2}/>
                           

                                {/* <div className="checkout-add">+ Add apartment, suite, etc.</div> */}
                                    <div className=" checkout-cards  mt-3  w-100 countrey-state">

                                        <div className=" flex justify-content-center mob-input col-sm-12 col-md-4  ">
                                            <div className="card flex justify-content-center">
                                                <Dropdown
                                                    value={countries && countries.find(country => country.name === selectedCountry)}
                                                    onChange={handleCountryChange}
                                                    options={countries}
                                                    optionLabel="name"
                                                    placeholder="Select a Country"
                                                    className="w-full md:w-14rem"
                                                    checkmark={true}
                                                    highlightOnSelect={false}
                                                />
                                                <input
                                                    type='text'
                                                    className={`addressModal-input5 col-sm-12 col-md h-100 visually-hidden`}
                                                    value={hiddenCountry}
                                                    name="country"
                                                    onChange={handleAutoFillChangeCountry}
                                                     // Standard autocomplete attribute for state
                                                />

                                            </div>
                                        </div>
                                        <div className=" flex justify-content-center mob-input col-sm-12 col-md-4 ">
                                            <Dropdown
                                                id="state-dropdown"
                                                value={states && states.find(state => state.name === selectedStates)}
                                                onChange={handleStateChange}
                                                options={states}
                                                optionLabel="name"
                                                placeholder="Select a state"
                                                className={`w-100 `}
                                                checkmark={true}
                                                highlightOnSelect={false}
                                                disabled={!states || states.length === 0}
                                            />
                                            <input
                                                type='text'
                                                className={`addressModal-input5 col-sm-12 col-md h-100 visually-hidden`}
                                                value={hiddenState}
                                                name="state"
                                                onChange={handleAutoFillChange}
                                                // Standard autocomplete attribute for state
                                            />

                                        </div>
                                        <input type="text" name="zip_code" placeholder='ZIP code' className='addressModal-input5 col-sm-12 col-md' value={contactInfo.zip_code} onChange={handleInputChange} required />

                                    </div>
                                    <div className="row w-100">
                                        <div className="col-4">
                                            {contactInfoError.country && <div className="error-msg2"> {contactInfoError.country}</div>}
                                        </div>
                                        <div className="col-4">
                                            {contactInfoError.state && <div className="error-msg2"> {contactInfoError.state}</div>}
                                        </div>
                                        <div className="col">
                                            {contactInfoError.zipcode && <div className="error-msg2"> {contactInfoError.zipcode}</div>}
                                        </div>
                                    </div>

                                    <input type="text" name="city" className='checkout-input2' placeholder='City' value={contactInfo.city} onChange={handleInputChange} />
                                    {contactInfoError.city && <div className="error-msg2"> {contactInfoError.city}</div>}

                                    <input type="text" name="phone" className='checkout-input2' placeholder='Phone ( optional)' value={contactInfo.phone} onChange={handleInputChange} />
                                    {contactInfoError.phone && <div className="error-msg2"> {contactInfoError.phone}</div>}

                                    <input type="text" name="note" className='checkout-input2' placeholder='Note' onChange={handleInputChange} value={contactInfo.note} />

                                    {/* -------------------------------------------shipment for brand -------------------------------------------- */}
                                    <div className="checkout-contact2"><img src="./assests/checkout-shipping.png" alt="" className='checkout-pencil' />Shipping method</div>
                                   {carts && carts.length > 0 && carts.map((cart, index) => (
                                        <div key={index} className='w-100'>
                                            {cart.products.length > 0 && (<div>
                                                <div className="checkout-shipment">Shipment {index + 1} of {cartsNumber > 0 ? cartsNumber : carts.length}</div>
                                                <div className="checkout-shipment-cont3">
                                                    <Accordion defaultActiveKey="0">
                                                        <Accordion.Item eventKey="0">
                                                            <Accordion.Header className="custom-accordion-header">
                                                                <div className="d-flex flex-column w-100">
                                                                    <div className="brand-header-acc">
                                                                        <div className="checkout-dhl"> {cart.brand.name}

                                                                        </div>
                                                                        <div className="checkout-20">${parseFloat(cart.total).toFixed(2)}</div>
                                                                    </div>


                                                                </div>

                                                            </Accordion.Header>
                                                            <div className="d-flex flex-column" style={{ padding: '0 1.25rem', backgroundColor: '#FEF9F5', paddingBottom: '0.5rem', borderBottom: '1px solid #E1CBB5' }}>
                                                                {
                                                                    !isUS ? (

                                                                        !cart.brand.is_international ? (
                                                                            <div className="text-danger">International Shipping unavailable</div>
                                                                        ) :
                                                                            (
                                                                                cart.shipping && cart.shipping.map(shipping => {
                                                                                    const isInternational = shipping.type.toLowerCase().includes('international');
                                                                                    const shouldRemoveClass = selectedCountry === 'United States' && isInternational;
                                                                                    return <div key={shipping.type} className={`checkout-noraml-cont2 ${shiping[cart.brand.id] === shipping.price ? 'checkout-noraml-cont' : ''} ${shouldRemoveClass ? 'd-none' : ''} ${!isUS && !isInternational ? 'd-none' : ''}`} onClick={() => updateShiping(cart.brand.id, shipping.price,shipping.type)}>
                                                                                        {!isUS && cart.is_international ? (
                                                                                            <div className="text-danger">International Shipping unavailable</div>
                                                                                        ) : (
                                                                                            <>
                                                                                                <label className="d-flex">
                                                                                                    <input checked={shiping[cart.brand.id] === shipping.price} type="radio" name={`ship-${cart.brand.id}`}
                                                                                                        onChange={() => updateShiping(cart.brand.id, shipping.price,shipping.type)} />
                                                                                                    <div className={` ${shiping[cart.brand.id] === shipping.price ? 'checkout-normal' : 'checkout-express2'}`}>{getBrandName(shipping.type)}</div>
                                                                                                </label>
                                                                                                <div className={` ${shiping[cart.brand.id] === shipping.price ? 'checkout-normal' : 'checkout-express2'}`}>{shipping.price === 0 ? 'Free' : `$${shipping.price}`}</div>
                                                                                            </>

                                                                                        )
                                                                                        }
                                                                                    </div>
                                                                                })
                                                                            )


                                                                    ) : (
                                                                        cart.shipping && cart.shipping.map(shipping => {
                                                                            const isInternational = shipping.type.toLowerCase().includes('international');
                                                                            const shouldRemoveClass = selectedCountry === 'United States' && isInternational;
                                                                            return <div key={shipping.type} className={`checkout-noraml-cont2 ${shiping[cart.brand.id] === shipping.price ? 'checkout-noraml-cont' : ''} ${shouldRemoveClass ? 'd-none' : ''} ${!isUS && !isInternational ? 'd-none' : ''}`} onClick={() => updateShiping(cart.brand.id, shipping.price,shipping.type)}>
                                                                                {!isUS && cart.is_international ? (
                                                                                    <div className="text-danger">International Shipping unavailable</div>
                                                                                ) : (
                                                                                    <>
                                                                                        <label className="d-flex">
                                                                                            <input checked={shiping[cart.brand.id] === shipping.price} type="radio" name={`ship-${cart.brand.id}`}
                                                                                                onChange={() => updateShiping(cart.brand.id, shipping.price,shipping.type)} />
                                                                                            <div className={` ${shiping[cart.brand.id] === shipping.price ? 'checkout-normal' : 'checkout-express2'}`}>{getBrandName(shipping.type)}</div>
                                                                                        </label>
                                                                                        <div className={` ${shiping[cart.brand.id] === shipping.price ? 'checkout-normal' : 'checkout-express2'}`}>{shipping.price === 0 ? 'Free' : `$${shipping.price}`}</div>
                                                                                    </>

                                                                                )
                                                                                }
                                                                            </div>
                                                                        })

                                                                    )

                                                                }

                                                                {/* <div className=" checkout-noraml-cont2">
                                                                    <div className="d-flex">
                                                                        <input type="radio" name={`ship-${cart.brand.id}`}
                                                                            onChange={() => updateShiping(cart.brand.id, cart.shipping[1].price)} />
                                                                        <div className="checkout-express2">Express Delivery</div>
                                                                    </div>
                                                                    <div className="checkout-express2">${cart.shipping[1].price}</div>
                                                                </div> */}
                                                                {shipingError[cart.brand.id] && <div className="error-msg2"> {'Please select a shipping option'}</div>}
                                                            </div>
                                                            <Accordion.Body>

                                                                {cart.products.map((product, index) => {
                                                                    let islast = cart.products.length - 1 === index ? true : false;
                                                                    return <React.Fragment key={product.product_id} >
                                                                        <div className="checkout-shipment-cont2">
                                                                            <div className="d-flex">
                                                                                <div className="checkout-menu">
                                                                                    <div className="checkout-swiper-img-1" onClick={() => { navigate(`/details/${product.product_id}`) }}>
                                                                                        <img src={product.images[0].image} alt="" onError={handleImageError} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="d-flex flex-column w-100">
                                                                                    <div className="checkout-rebel">{cart.brand.name}</div>
                                                                                    <div className="d-flex justify-content-between">
                                                                                        <div className="checkout-golden">{product.product_name}</div>

                                                                                        <div className="checkout-golden">{product.out_of_stock === 1 ? (
                                                                                            <div className='error-msg2'>Sold Out</div>
                                                                                        ) : (
                                                                                            `$${parseFloat(product.product_total).toFixed(2)}`
                                                                                        )}</div>

                                                                                    </div>
                                                                                    {/* {shipingError[product.id] && <div className="error-msg3"> {'Out Of Stock'}</div>} */}

                                                                                    {product.variation_attributes.variation && (
                                                                                        <div className="checkout-color">{product.variation_attributes.variation} : {product.variation_attributes.variation_value}</div>
                                                                                    )}
                                                                                    {product.variation_attributes.variation2 && (
                                                                                        <div className="checkout-color">{product.variation_attributes.variation2} : {product.variation_attributes.vartion_value2}</div>
                                                                                    )}
                                                                                    {product.variation_attributes.variation3 && (
                                                                                        <div className="checkout-color">{product.variation_attributes.variation3} : {product.variation_attributes.vartion_value3}</div>
                                                                                    )}

                                                                                    <div className="d-flex mt-3 align-items-center">
                                                                                        <div className="detailsModal-plus-minus d-flex">
                                                                                            <div className="details-plus5" onClick={() => handleDecrement(product.id, cart.brand.id)}>-</div>
                                                                                            <div className="">{product.quantity}</div>
                                                                                            <div className="details-plus5" onClick={() => handleIncrement(product.id, cart.brand.id)}>+</div>
                                                                                        </div>
                                                                                        <div className="detailsModal-remove" onClick={() => { DeleteItem(product.id, product.product_total, islast, cart.brand.id) }}>Remove</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </React.Fragment>
                                                                })}
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>



                                                </div>
                                            </div>)}
                                        </div>
                                    ))}



                                    {/*--------------------------- card info -------------------------------------- */}
                                    {/* <div className="checkout-contact2"><img src="./assests/checkout-expense.png" alt="" className='checkout-pencil' />Payment</div>
                            <div className="checkout-shipment-cont6">
                                <div className={"checkout-shipment-cont " + (payment1 && "checkout-payment-container")}>
                                    <div className="d-flex align-items-center">
                                        <input type="radio" name="payment" id="payment1" onClick={openPayment1} />
                                        <div className="checkout-dhl ">Credit card</div>
                                        <div className="checkout-20"><div className="footer-cash-methods">
                                            <img src="./assests/footer-visa.png" alt="" />
                                            <img src="./assests/footer-master.png" alt="" />
                                            <img src="./assests/footer-express.png" alt="" />
                                            <img src="./assests/footer-pay.png" alt="" />
                                            <div className="checkout-3">+3</div>
                                        </div></div>
                                    </div>

                                </div>
                                {payment1 && (
                                    <div className="checkout-shipment-cont4">
                                        <input type="text" className='checkout-input' placeholder='Card Number' />
                                        <div className="d-flex mt-3">
                                            <input type="text" className='checkout-input me-3' placeholder='Card Number' />
                                            <input type="text" className='checkout-input' placeholder='Card Number' />
                                        </div>
                                        <input type="text" className='checkout-input mt-3' placeholder='Name on card' />
                                        <div className="d-flex mt-3">
                                            <input type="checkbox" name="" id="" />
                                            <div className="ms-2 checkout-use">Use shipping address as billing address</div>
                                        </div>
                                    </div>
                                )}

                                <div className={"checkout-shipment-cont5 " + (payment2 && "checkout-payment-container")}>
                                    <div className=""><input type="radio" name="payment" id="payment2" onClick={openPayment2} />  paypal</div>

                                    <img src="./assests/footer-pal.png" alt="" />
                                </div>
                                {payment2 && (
                                    <div className="checkout-shipment-cont4">
                                        <div className="checkout-after">After clicking "Pay with PayPal", you will be redirected to PayPal to complete your <p> purchase securely.</p></div>
                                    </div>
                                )}
                            </div>
                            <div className="checkout-shipment">Remember me</div>
                            <div className="checkout-check-save">
                                <input type="checkbox" />
                                <div className="checkout-save"> Save my information for faster checkout</div>
                            </div> */}



                                    {true && <div className='payment-web'>

                                        <button className="checkout-pay" onClick={Checkout}>PAY NOW</button>
                                        <div className="d-flex justify-content-between w-100 my-4">
                                            <Link to='/refund' className="checkout-refund">Refund policy</Link>
                                            <Link to='/shipping' className="checkout-refund">Shipping policy</Link>
                                            <Link to='/privacy' className="checkout-refund">Privacy policy</Link>
                                            <Link to='/terms' className="checkout-refund">Terms of service</Link>
                                            <Link to='/contact' className="checkout-refund">Contact information</Link>
                                        </div>
                                    </div>}
                                </div>
                                {/* --------------------------------- right section---------------------------------------------------- */}
                                <div className="checkout-right">
                                    <div className="header-added-img2">
                                        <img src="./assests/header-croc.svg" alt="" />
                                    </div>
                                    <div className="checkout-right-cont">
                                        <div className="order-list">
                                        {carts && carts.length > 0 && carts.map((cartItem, index) => (
                                                <div key={index}>
                                                    {cartItem.products.map((product => (
                                                        <React.Fragment key={product.id}>
                                                            <div className="checkout-img-name-price">
                                                                <div className="img-name d-flex align-items-center w-100">
                                                                    <div className="checkout-img">
                                                                        <img src={product.images && product.images.length > 0 ? product.images[0].image : defaultImage}
                                                                            alt={product.name} onError={handleImageError} />
                                                                        <div className="product-qty">{product.quantity}</div>
                                                                    </div>
                                                                    <div className="checkout-golden-golden2">
                                                                        <div className="checkout-golden">
                                                                            {/* {item.product_variation_value.product.name} */}
                                                                            {product.product_name}
                                                                        </div>
                                                                        <div className="checkout-golden" id='checkout-golden'>
                                                                            {product.out_of_stock === 1 ? (
                                                                                <div className='error-msg2'>Sold Out</div>
                                                                            ) : (
                                                                                `$${parseFloat(product.product_total).toFixed(2)}`
                                                                            )}


                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </React.Fragment>)
                                                    ))
                                                    }

                                                </div>

                                            ))}
                                        </div>


                                        {cartLength > 0 && (
                                            <>
                                                {/* <div className="checkout-input-apply">
                                            <input type="text" name='discount' className='checkout-input' placeholder='Discount code or gift carf' value={contactInfo.discount} onChange={handleInputChange} required />
                                            <div className="checkout-apply">Apply</div>
                                        </div> */}
                                                <div className="checkout-subtotal-price">
                                                    <div className="checkout-golden">Subtotal</div>
                                                    <div className="checkout-golden">${parseFloat(subTotal).toFixed(2)}</div>
                                                </div>
                                                <div className="checkout-subtotal-price">
                                                    <div className="checkout-golden">Shipping</div>
                                                    <div className="checkout-golden">${parseFloat(totalShipping).toFixed(2)}</div>
                                                </div>
                                                <div className="checkout-subtotal-price">
                                                    <div className="checkout-total">Total</div>
                                                    <div className="checkout-price"><span>USD</span>${parseFloat(subTotal + totalShipping).toFixed(2)}</div>
                                                </div>
                                            </>
                                        )}
                                        {true && <div className='payment-mob'>

                                            <button className="checkout-pay my-2" onClick={Checkout}>PAY NOW</button>
                                            <div className="d-flex justify-content-around w-100 my-4 flex-wrap py-2" style={{ gap: '10px' }}>
                                                <Link to='/refund' className="checkout-refund">Refund policy</Link>
                                                <Link to='/shipping' className="checkout-refund">Shipping policy</Link>
                                                <Link to='/privacy' className="checkout-refund">Privacy policy</Link>
                                                <Link to='/terms' className="checkout-refund">Terms of service</Link>
                                                <Link to='/contact' className="checkout-refund">Contact information</Link>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div >
                )}
            </div >
        </>
    )
}
